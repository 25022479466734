import React from 'react';
import SEO from '../components/SEO';
import { ImageGrid, ImageCaption } from '../styles/ImageGrid';
import Waitresses from '../assets/images/waitresses.jpg';
import Honey from '../assets/images/honey.jpg';
import Baileywithmike from '../assets/images/baileywithmike.jpg';
import Usoutsideoxford2withwaitress from '../assets/images/usoutsideoxford2withwaitress.jpg';
import Baileywithmikethecook from '../assets/images/baileywithmike-cook.jpg';
import Uswithcop from '../assets/images/uswithcop.jpg';
import Baileykevinpoohwithholyriders from '../assets/images/baileykevinpoohwithholyriders.jpg';
import Baileykevinpoohwithfans from '../assets/images/baileykevinpoohwithfans.jpg';
import Oxfordregulars from '../assets/images/oxfordregulars.jpg';
import Baileywithvampire from '../assets/images/baileywithvampire.jpg';
import Usatlincolnhh from '../assets/images/usatlincolnhh.jpg';
import Jackiewithbuddylee from '../assets/images/jackiewithbuddylee.jpg';
import Uswithchester from '../assets/images/uswithchester.jpg';

export default function FriendsPage() {
  return (
    <>
      <SEO title="Friends" />
      <div>
        <p>
          This is the friends section of the Huddle House Fan Club. This is
          where you can find a catergoized list of all the wonderful friends we
          have made while going to the Huddle House.
        </p>
      </div>
      <h2 className="center" style={{ margin: '1rem 0' }}>
        Huddle House Friends
      </h2>
      <ImageGrid>
        <div>
          <img
            src={Waitresses}
            alt="The Oxford Huddle Waitresses: Cristy, Joann, Sarah"
          />
          <ImageCaption>
            The Oxford Waitresses: Cristy, Joann, Sarah
          </ImageCaption>
        </div>
        <div>
          <img src={Honey} alt="Honey" />
          <ImageCaption>Honey</ImageCaption>
        </div>
        <div>
          <img src={Baileywithmike} alt="Mike" />
          <ImageCaption>Mike</ImageCaption>
        </div>
        <div>
          <img
            src={Usoutsideoxford2withwaitress}
            alt="Sandra the Oxford Waitress"
          />
          <ImageCaption>Sandra the Oxford Waitress</ImageCaption>
        </div>
        <div>
          <img src={Baileywithmikethecook} alt="Mike the Oxford 2 Cook" />
          <ImageCaption>Mike the Oxford 2 Cook</ImageCaption>
        </div>
        <div>
          <img src={Uswithcop} alt="The Cop that Goes to Oxford 2" />
          <ImageCaption>The Cop that Goes to Oxford 2</ImageCaption>
        </div>
        <div>
          <img src={Baileykevinpoohwithholyriders} alt="The Holey Riders" />
          <ImageCaption>The Holy Riders</ImageCaption>
        </div>
        <div>
          <img src={Baileykevinpoohwithfans} alt="Oxford 2 Fans" />
          <ImageCaption>Oxford 2 Fans</ImageCaption>
        </div>
        <div>
          <img src={Oxfordregulars} alt="Oxford Regulars" />
          <ImageCaption>Oxford Regulars</ImageCaption>
        </div>
        <div>
          <img src={Baileywithvampire} alt="Vampire" />
          <ImageCaption>Vampire</ImageCaption>
        </div>
        <div>
          <img src={Usatlincolnhh} alt="Sheila" />
          <ImageCaption>Sheila</ImageCaption>
        </div>
        <div>
          <img src={Jackiewithbuddylee} alt="Jackie" />
          <ImageCaption>Jackie</ImageCaption>
        </div>
        <div>
          <img src={Uswithchester} alt="Chester" />
          <ImageCaption>Chester</ImageCaption>
        </div>
      </ImageGrid>
    </>
  );
}
